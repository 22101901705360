'use strict';
/* globals dataLayer */

const { pushToDataLayer } = require('./analyticsHelpers');

/**
 * Push the breadcrumb clicks to the datalayer.
 * @param {jQuery} breadCrumbElement - The clicked breadcrumb element
 */
function pushBreadCrumb(breadCrumbElement) {
    var $breadCrumbItemsBefore = $(breadCrumbElement).prevAll().not('.breadcrumb-item-mobile');
    var currentBreadcrumbPath = $(breadCrumbElement).closest('.breadcrumb').data('path');
    var clickedBreadCrumbPath = '';

    $breadCrumbItemsBefore.each(function (index, item) {
        var text = $(item).find('span').text();

        clickedBreadCrumbPath = text + ' > ' + clickedBreadCrumbPath;
    });

    clickedBreadCrumbPath += $(breadCrumbElement).last().find('span').text();

    const event = {
        event: 'breadcrumb',
        status: currentBreadcrumbPath && currentBreadcrumbPath.replace(/\//g, ' > '),
        selection: clickedBreadCrumbPath
    };

    if (currentBreadcrumbPath) {
        pushToDataLayer(event);
    }
}

/**
 * Gets the userState. The user can be 'Logged In', 'Recognized' or 'Not Logged In'
 * @returns {string} - User Status
 */
function getUserStatus() {
    const $authData = $('.js-auth-data');
    const authenticated = $authData.data('authenticated');
    const registered = $authData.data('registered');
    if (authenticated && registered) {
        return 'Logged in';
    } else if (!authenticated && registered) {
        return 'Recognized';
    }
    return 'Not logged in';
}

module.exports = {
    initSiteInfo: function () {
        const event = {
            event: 'siteInfo',
            locale: `${$('html').attr('lang')}-${$('html').attr('data-country')}`,
            controller: $('.page').data('action')
        };
        pushToDataLayer(event);
    },
    initBreadCrumbs: function () {
        $('.breadcrumb').ready(function () {
            pushBreadCrumb($(this).find('.breadcrumb-item'));
        });
    },
    initUserStatus: function () {
        const userStatus = getUserStatus();
        const event = {
            event: 'userStatus',
            value: userStatus
        };
        pushToDataLayer(event);
    },
    initUserLogin: function () {
        $(document).on('login:success', function () {
            const event = {
                event: 'loginStatus',
                value: 'Login successful'
            };
            pushToDataLayer(event);
        });

        $(document).on('login:error', function () {
            const event = {
                event: 'loginStatus',
                value: 'Login not successful'
            };
            pushToDataLayer(event);
        });
    },
    initNewsletterSubscription: function () {
        $(document).on('newsletter:subscribed', function () {
            const event = {
                event: 'newsSubscription',
                value: 'newsletter subscription completed'
            };
            pushToDataLayer(event);
        });
    },
    initJavascriptLoaded: function () {
        const event = {
            event: 'javascript',
            value: 'loaded'
        };
        pushToDataLayer(event);
    },

    initCookiesLoaded: function () {
        $('body').on('cookies:loaded', function () {
            const event = {
                event: 'cookies',
                value: 'loaded'
            };
            pushToDataLayer(event);
        });
    },

    accountCreation: function () {
        $(document).on('login:registered', function () {
            const event = {
                event: 'account-create-success-fr-nl',
                value: 'Registration successful'
            };
            pushToDataLayer(event);
        });
    },

    triggerChangeFlowEvent: function () {
        $(document).on('shipping:updateFlow', function (e, flowName) {
            const event = {
                event: 'changeFlow',
                value: flowName
            };
            pushToDataLayer(event);
        });
    }
};

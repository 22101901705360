'use strict';
/* globals dataLayer */

/**
 * Updates the event property of the ecommerceData object based on the method determined
 * by the 'data-rg-active' attribute of the HTML tag.
 *
 * @param {Object} ecommerceData - The ecommerce data object that contains the event property.
 * @param {string} ecommerceData.event - The event string to be updated.
 * @returns {Object} The updated ecommerceData object with the modified event property.
 */
function updateEventByMethod(ecommerceData) {
    if (typeof ecommerceData.event === 'undefined' || ecommerceData.event === 'undefined') {
        return ecommerceData;
    }

    var dataRgActive = $('html').attr('data-rg-active') === 'true';
    var updatedEcommerceData = Object.assign({}, ecommerceData);
    updatedEcommerceData.flowType = dataRgActive ? 'reservation' : 'ecommerce';

    return updatedEcommerceData;
}

/**
 * Pushes the event object to the data layers if they exist.
 * @param {Object} event - The event object to be pushed to the data layers.
 */
function pushToDataLayer(event) {
    if ('dataLayer' in window) {
        var updatedEvent = updateEventByMethod(event);
        dataLayer.push(updatedEvent);
    }
}

module.exports = {
    pushToDataLayer
};
